import igIcon from "./icons/logo-instagram.svg";
import fbIcon from "./icons/logo-facebook.svg";
import mailIcon from "./icons/mail-outline.svg";
import "./App.css";
import { Container, Heading, Image, Link, Text } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useEffect } from "react";

const iconVariants = {
  hover: {
    scale: 1.1,
    transition: {
      duration: 0.3, // Adjust the duration as needed
    },
  },
};

function App() {
  useEffect(() => {
    document.title = "SkullerudSett"; // Set the new title here
  }, []);

  return (
    <Container
      w={"full"}
      h={"100vh"}
      color={"white"}
      fontFamily={"sans-serif"}
      textAlign={"center"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Container>
        <Container>
          <Heading>Under Konstruksjon</Heading>
          <Text>Jeg kan kontaktes via IG, Facebook eller E-Mail!</Text>
        </Container>
        <Container
          display={"flex"}
          w={"full"}
          h={"30px"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={"20px"}
          pt={"15px"}
        >
          <Link
            href="https://www.facebook.com/linamaria.skullerud"
            target="_blank"
            as={motion.a}
            variants={iconVariants}
            whileHover="hover"
          >
            <Image h={"40px"} src={fbIcon} alt="Facebook icon" />
          </Link>
          <Link
            href="https://www.instagram.com/limanaria/"
            target="_blank"
            as={motion.a}
            variants={iconVariants}
            whileHover="hover"
          >
            <Image h={"40px"} src={igIcon} alt="Instagram icon" />
          </Link>
          <Link
            href="mailto:linamariaskullerud@hotmail.com"
            as={motion.a}
            variants={iconVariants}
            whileHover="hover"
          >
            <Image h={"45px"} src={mailIcon} alt="Mail icon" />
          </Link>
        </Container>
      </Container>
    </Container>
  );
}

export default App;

// <Box>
//   <Link
//     href="https://www.facebook.com/linamaria.skullerud"
//     target="_blank"
//     rel="noreferrer"
//   >
//     <AspectRatio maxW={"20px"} ratio={1}>
//       <Image w={"20px"} src={fbIcon} alt="Facebook icon" />
//     </AspectRatio>
//   </Link>
//   <Link
//     href="https://www.instagram.com/limanaria/"
//     target="_blank"
//     rel="noreferrer"
//   >
//     <AspectRatio maxW={"20px"} ratio={1}>
//       <Image src={igIcon} alt="Instagram icon" />
//     </AspectRatio>
//   </Link>
//   <Link href="mailto:linamariaskullerud@hotmail.com">
//     <AspectRatio maxW={"20px"} ratio={1}>
//       <Image src={mailIcon} alt="Mail icon" />
//     </AspectRatio>
//   </Link>
// </Box>;
